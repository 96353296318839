/**
 * util.js
 * author: hiroki (http://hirokifujikami.com)
 */

var hrkUtil = hrkUtil || {};

hrkUtil = {


/* check if it's touch device or not
---------------------------------------------*/
	touchDevice: function (){
		var check = false;
		(function(a) {
			if (/(android|ipad|playbook|silk|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|xs700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
				check = true;
		})(navigator.userAgent || navigator.vendor || window.opera);
		return check;
	},

/* get userAgent / OS
---------------------------------------------*/
	UA: {
		getUserAgent : function(){
			return window.navigator.userAgent.toLowerCase();
		},
		getAppVersion : function(){
			return window.navigator.appVersion.toLowerCase();
		},
		getOS : function(){
			var pf = navigator.platform,
			os;
			if (pf.indexOf('Win') != -1) {
				os = 'win';
			}
			else if (pf.match(/Mac|PPC/)) {
				os = 'mac';
			}
			else if (pf.match(/Linux/)) {
				os = 'Linux';
			}
			else if (pf.match(/^.*\s([A-Za-z]+BSD)/)) {
				os = RegExp.$1;
			}
			else if (pf.match(/SunOS/)) {
				os = 'Solaris';
			}
      else if (pf.match(/iPhone/) || pf.match(/iPod/) || pf.match(/iPad/)) {
				os = 'ios';
			}
      else if (pf.match(/Android/) ){
				os = 'android';
			}
			else {
				os = 'N/A';
			}
			return os;
		},
		getIEVersion : function(){
			var browser = "not IE";
			var userAgent = hrkUtil.UA.getUserAgent();
			if (userAgent.indexOf('msie') != -1) {
				browser = 'ie';
				if (appVersion.indexOf('msie 6.') != -1) {
					browser = 'ie6';
				} else if (appVersion.indexOf('msie 7.') != -1) {
					browser = 'ie7';
				} else if (appVersion.indexOf('msie 8.') != -1) {
					browser = 'ie8';
				} else if (appVersion.indexOf('msie 9.') != -1) {
					browser = 'ie9';
				} else if (appVersion.indexOf('msie 10.') != -1) {
					browser = 'ie10';
				}
			};
			return browser;
		},

	},


/* get scroll position (どれだけスクロールしたかを判定)
---------------------------------------------*/
	getScrollY: function(){
		return document.documentElement.scrollTop || document.body.scrollTop;
	},


/* get width of the screen (ブラウザの表示領域（各種バーを含まない領域）を表示するスクリプト)
---------------------------------------------*/
	getWindowW: function(){
		return document.documentElement.clientWidth;
	},

/* get height of the screen (ブラウザの表示領域（各種バーを含まない領域）を表示するスクリプト)
---------------------------------------------*/
	getWindowH: function(){
		return document.documentElement.clientHeight;
	},

/* check if it's Retina display or not (Firefox や IE)
---------------------------------------------*/
	retinaScreen: function(){
		if (window.matchMedia) {
			var mq = window.matchMedia("only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");
			return (mq && mq.matches || (window.devicePixelRatio > 1));
		}
	},

/* check if its parent elements have the class name (要素の親に指定のクラス名があるかどうかを調べるスクリプト)
---------------------------------------------*/
	hasParentClass: function( e, classname ) {
		if (e === document)
			return false;
		if ( classie.has( e, classname ) ) {
			return true;
		}
		return e.parentNode && hrkUtil.hasParentClass( e.parentNode, classname );
	},

}



hrkUtil.util = $.extend({ // 以下のオブジェクトをマージ

	/* htmlAddClass
--------------------------------------------------*/
	htmlAddClass: function(){
		var $html = $('html');
		if( hrkUtil.touchDevice() ) {
			$html.addClass('touch');
		} else {
			$html.addClass('no-touch');
		};
	},



/* ie8用にaddEventListenerを定義
---------------------------------------------*/
	pollyEventListerner: function(){
		if (!Event.prototype.preventDefault) {
			Event.prototype.preventDefault=function() {
				this.returnValue=false;
			};
		}
		if (!Event.prototype.stopPropagation) {
			Event.prototype.stopPropagation=function() {
				this.cancelBubble=true;
			};
		}
		if (!Element.prototype.addEventListener) {
			var eventListeners=[];

			var addEventListener=function(type,listener /*, useCapture (will be ignored) */) {
				var self=this;
				var wrapper=function(e) {
					e.target=e.srcElement;
					e.currentTarget=self;
					if (listener.handleEvent) {
						listener.handleEvent(e);
					} else {
						listener.call(self,e);
					}
				};
				if (type=="DOMContentLoaded") {
					var wrapper2=function(e) {
						if (document.readyState=="complete") {
							wrapper(e);
						}
					};
					document.attachEvent("onreadystatechange",wrapper2);
					eventListeners.push({object:this,type:type,listener:listener,wrapper:wrapper2});

					if (document.readyState=="complete") {
						var e=new Event();
						e.srcElement=window;
						wrapper2(e);
					}
				} else {
					this.attachEvent("on"+type,wrapper);
					eventListeners.push({object:this,type:type,listener:listener,wrapper:wrapper});
				}
			};
			var removeEventListener=function(type,listener /*, useCapture (will be ignored) */) {
				var counter=0;
				while (counter<eventListeners.length) {
					var eventListener=eventListeners[counter];
					if (eventListener.object==this && eventListener.type==type && eventListener.listener==listener) {
						if (type=="DOMContentLoaded") {
							this.detachEvent("onreadystatechange",eventListener.wrapper);
						} else {
							this.detachEvent("on"+type,eventListener.wrapper);
						}
						eventListeners.splice(counter, 1);
						break;
					}
					++counter;
				}
			};
			Element.prototype.addEventListener=addEventListener;
			Element.prototype.removeEventListener=removeEventListener;
			if (HTMLDocument) {
				HTMLDocument.prototype.addEventListener=addEventListener;
				HTMLDocument.prototype.removeEventListener=removeEventListener;
			}
			if (Window) {
				Window.prototype.addEventListener=addEventListener;
				Window.prototype.removeEventListener=removeEventListener;
			}
		}
	},


/* リサイズ時の間引き処理
---------------------------------------------*/
	windowResize: function(fn, interval, eventName) {
		var eventInterval = ( interval )? interval : 100,
		eventID = (eventName)? eventName : "",
		windowWidth = $(window).width(),
		timer;

		$(window).on('resize'+eventID, function(){
			clearTimeout(timer);
			timer = setTimeout(function() {
				fn();
			}, eventInterval);
		});
	},

});



hrkUtil.util = $.fn.extend({

/* ページ内リンクへのスムーズスクロール
---------------------------------------------*/
	smoothScroll: function(options){
		var options = $.extend({
			offset : 0,
			speed : 500,
		}, options);
		var eventtype = hrkUtil.touchDevice() ? 'touchstart' : 'click';
		var move = hrkUtil.touchDevice() ? 'touchstart' : 'mousewheel DOMMouseScroll MozMousePixelScroll';
		var scr = $(/(webkit)[ \/]([\w.]+)/i.test(navigator.userAgent) ? "body" : "html");
		$(this).on(eventtype,function() {
			event.preventDefault();
			scr.on(move, function(event) {
				event.preventDefault();
			});
			var href= $(this).attr("href");
			if($(href).length){
				var target = $(href == "#" || href === "" ? 'html' : href);
				var position = target.offset().top - options.offset;
				 scr.animate({scrollTop:position}, options.speed, function(){
					scr.off(move);
				});
			}
			return false;
		});
	},
	smoothScrollV: function(options){
		var options = $.extend({
			offset : 0,
			speed : 500,
			easing : "easeOutExpo"
		}, options);
		var eventtype = hrkUtil.touchDevice() ? 'touchstart' : 'click';
		var move = hrkUtil.touchDevice() ? 'touchstart' : 'scroll';
		var scr = $(/(webkit)[ \/]([\w.]+)/i.test(navigator.userAgent) ? "body" : "html");
		$(this).on(eventtype,function() {
			event.preventDefault();
			scr.on(move, function(event) {
				event.preventDefault();
			});
			var href= $(this).attr("href");
			if($(href).length){
				var target = $(href == "#" || href === "" ? 'html' : href);
				var position = target.offset().top - options.offset;
				target.velocity("scroll", {
					offset : options.offset,
					duration: options.speed,
					easing: options.easing,
					complete: function() {
						scr.off(move);
					}
				});
			}
			return false;
		});
	},
/* fadeover
--------------------------------------------------*/
	fadeover: function(options){
		var options = $.extend({
			opacity: 0.7,
			time: 500
		}, options);
		$(this).on({
			'mouseover': function(){
				$(this).stop().animate({'opacity':options.opacity}, options.time);
			},
			'mouseout': function(){
				$(this).stop().animate({'opacity':1}, options.time);
			}
		});
	},
	fadeoverV: function(options){
		var options = $.extend({
			opacity: 0.7,
			time: 500
		}, options);
		$(this).on({
			'mouseover': function(){
				$(this).velocity("stop").velocity({'opacity':options.opacity}, options.time);
			},
			'mouseout': function(){
				$(this).velocity("stop").velocity({'opacity':1}, options.time);
			}
		});
	},



});
